<template>
  <div>
    <el-row style="margin-top: 20px">
      <el-form :inline="true" :model="queryForm">
        <el-form-item label="查询" class="formItemBoxStyle">
          <el-input
            v-model="queryForm.query"
            placeholder="请输入"
            @input="getAiMarkingListApiFunc(queryForm.query)"
            clearable
          ></el-input>
        </el-form-item>

        <el-button type="primary" icon="el-icon-refresh" @click="refresh"
          >刷新</el-button
        >
        <!-- <el-button type="danger" @click="deleteBtn(true)">批量删除</el-button> -->
        <!-- <el-button type="primary" @click="()=>{this.$router.push('/scoreAI/exportHistoryScore')}">结果导出</el-button> -->
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-row>
      <el-table
        v-loading="loading"
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="tableData"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column
          type="index"
          align="center"
          width="55"
          label="序号"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="studentCode"
          label="学号"
          min-width="140"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="experimentResultCode"
          label="实验记录唯一编码"
          min-width="240"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="aiOperationTestCode"
          label="实验编码"
          min-width="180"
        >
          <template slot-scope="scope">{{
            scope.row.aiOperationTestCode
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="aiOperationTestName"
          label="实验名称"
          min-width="340"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="aiOperationTestVersion"
          label="AI评分版本"
          width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="aiResultTime"
          label="评分提交时间"
          show-overflow-tooltip
          width="180px"
        >
          <template slot-scope="scope">{{
            scope.row.aiResultTime | dayList
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="resultConfidence"
          label="置信"
          width="70"
        >
          <template slot-scope="resultConfidence">
            <span
              :style="
                resultConfidence.row.resultConfidence
                  ? 'color:#67C23A'
                  : 'color:#E6A33F'
              "
              >{{ resultConfidence.row.resultConfidence ? '是' : '否' }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="aiScoreFull"
          label="总分"
          width="60"
        >
        </el-table-column>
        <el-table-column align="center" prop="aiScore" label="得分" width="60">
          <template slot-scope="aiScore">
            <span
              :style="
                aiScore.row.aiScoreFull > aiScore.row.aiScore
                  ? 'color:#E6A33F'
                  : ''
              "
              >{{ aiScore.row.aiScore }}</span
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="viewBtn(scope.row)"
              >查看</el-button
            >
            <!-- <el-button
              type="danger"
              size="mini"
              @click="deleteBtn(false, scope.row)"
            >
              删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableDataPagination.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableDataPagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataPagination.total"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
// import {
//   getAiMarkingListApi,
//   getPageAiMarkRecordListApi,
// } from '@/api/marking/ai.js'
import dayjs from 'dayjs'
import {
  getAiMarkRecordApi,
  deleteAiMarkRecordApi,
} from '@/api/marking/historyScoreAI.js'
export default {
  name: 'notResultConfidence',
  data() {
    return {
      loading: false,
      queryForm: {
        query: '',
      },
      tableData: [],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 20,
        // 总数量
        total: 0,
      },
      selectionList: [],
    }
  },
  mounted() {
    this.getAiMarkingListApiFunc()
  },
  methods: {
    getAiMarkingListApiFunc(query) {
      this.loading = true
      let formData = new FormData()
      formData.append('query', query || '')
      formData.append('resultConfidence', 0)
      getAiMarkRecordApi(
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        formData
      )
        .then((res) => {
          this.loading = false
          console.log('res', res)
          if (res.success) {
            this.selectionList = []
            this.tableData = res.data
            this.tableDataPagination.total = res.total
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    viewBtn(val) {
      console.log(val)
      this.$router.push({
        path: '/scoreAI/detailHistoryScore',
        query: {
          experimentUuid: val.experimentResultCode,
          aiResultPractiseClassRecordId: val.aiResultPractiseClassRecordId,
        },
      })
    },
    refresh() {
      this.queryForm.query = ''
      this.tableDataPagination.pageIndex = 1
      this.getAiMarkingListApiFunc(this.queryForm.query)
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      this.tableDataPagination.pageIndex = 1
      this.getAiMarkingListApiFunc()
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      this.getAiMarkingListApiFunc()
    },
    handleSelectionChange(val) {
      this.selectionList = val
      console.log('selectionList', this.selectionList)
    },
    // 删除按钮
    deleteBtn(flayList, row) {
      console.log('row', row, this.selectionList)
      let arr = []
      if (flayList) {
        if (!this.selectionList || !this.selectionList.length) {
          return this.$message.info('请先勾选')
        }
        arr = this.selectionList.map((e) => e.aiResultPractiseClassRecordId)
      } else {
        arr = [row.aiResultPractiseClassRecordId]
      }
      let fd = new FormData()
      fd.append('idList', arr)
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let loadingInstance = Loading.service({
            fullscreen: true,
            text: '删除中',
          })
          await this.deleteAiMarkRecordApiFunc(fd)
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 删除
    async deleteAiMarkRecordApiFunc(list) {
      await deleteAiMarkRecordApi(list)
        .then((res) => {
          if (res.success) {
            this.$message.success('删除成功')
            this.refresh()
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {})
    },
  },
  filters: {
    dayList(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
  },
}
</script>

<style></style>
